<template>
   <div>
      <Header />
      <Atletas />
      <Footer />
   </div>
</template>

<script>
import Atletas from "../components/Atletas/Atletas.vue";
export default {
   components: {
      Atletas,
   },
};
</script>

<style></style>
