<template>
   <div class="ma-6">
      <Atleta @save="$refs.ok_noti.Show()" ref="atleta" />
      <Dialog message="¿Desea eliminar el atleta?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table
         :loading="isloading"
         :headers="headers"
         :items="$store.state.atletas"
         :items-per-page="5"
         class="elevation-1 mb-8"
         :search="search"
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="newItem()"><v-icon left>mdi-plus-circle</v-icon>Nuevo atleta</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:[`item.categoria`]="{ item }">
            <v-icon small color="pink" v-if="item.categoria == 'F'">mdi-face-woman</v-icon>
            <v-icon small color="blue" v-else>mdi-face-man</v-icon>
         </template>
         <template v-slot:[`item.nombre`]="{ item }">
               {{ item.nombre }} [{{ item.num }}]
         </template>
         <template v-slot:[`item.rango`]="{ item }">
            <div class="pretty_title" v-if="item.rango == 'R'">RX</div>
            <div class="pretty_title" v-else-if="item.rango == 'A'">Avanzado</div>
            <div class="pretty_title" v-else-if="item.rango == 'M'">Master</div>
            <div class="pretty_title" v-else-if="item.rango == 'I'">Intermedio</div>
            <div class="pretty_title" v-else-if="item.rango == 'K'">Kids</div>
            <div class="pretty_title" v-else>Principiante</div>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Atleta from "./Atletas/Atleta.vue";

export default {
   components: {
      Atleta,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "atleta_id" },
            { text: "Nombre", value: "nombre" },
            { text: "Categoria", value: "categoria" },
            { text: "Rango", value: "rango" },
            { text: "Gym", value: "gym" },
            { text: "Telefono", value: "telefono" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
         search: "",
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getAtletas");
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         this.$refs.atleta.Show({
            atleta_id: null,
            nombre: "",
            num: "",
            categoria: "V",
            rango: "P",
            gym: "",
            telefono: "",
         });
      },
      async editItem(item) {
         this.$refs.atleta.Show({
            atleta_id: item.atleta_id,
            nombre: item.nombre,
            num: item.num,
            categoria: item.categoria,
            rango: item.rango,
            gym: item.gym,
            telefono: item.telefono,
         });
      },
      async deleteItem(item) {
         this.itemToDelete = item;
         this.$refs.deleteItem.Show();
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteAtleta", {
               atleta_id: this.itemToDelete.atleta_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style scoped>
.pretty_title {
   font-size: 9px;
   font-weight: 900;
   text-transform: uppercase;
   letter-spacing: 2px;
}
.pretty_title_2 {
   font-size: 12px;
   font-weight: 800;
   letter-spacing: 2px;
}
</style>
